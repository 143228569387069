iframe {
  width: 100vw;
  height: 100vh;
  border: none;
  overflow: hidden;
}

h1 {
  font-weight: 700;
  font-size: 7rem;
  margin: 0;
}
h2 {
  font-weight: 600;
  font-size: 5rem;
  color: var(--darkBlue);
  margin: 0;
  padding-top: 5rem;
}

h3 {
  font-weight: 600;
  font-size: 4.5rem;
  color: var(--lightblue);
  margin: 0;
}

h4 {
  font-weight: 400;
  font-size: 3rem;
  color: var(--darkBlue);
  margin: 0;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  position: relative;
}

img {
  height: 100vh;
  overflow-y: hidden;
}

.rose {
  height: 500px;
  width: 500px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  text-align: center;
}

.userText {
  position: absolute;
  left: 0;
  right: 0;
  top: 22%;
  text-align: center;
  z-index: 10;
  font-size: 5rem;
}

.object {
  position: absolute;
  margin: auto;
  bottom: -500px;
  height: 250px;
  left: 0;
  right: 0;
}

@keyframes MoveDown {
  0% {
    bottom: 100vh;
  }
  100% {
    bottom: -260px;
  }
}


@keyframes fontGrow {
  0% {
    font-size: 5rem;
  }
  50% {
    font-size: 7rem;
  }
  100% {
    font-size: 5rem;
  }
}